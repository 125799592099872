export enum StepStatus {
  INITIAL = 1,
  ALMOST_PROSPECT = 2,
  PROSPECT = 3,
  ALMOST_CUSTOMER = 4,
  CUSTOMER = 5
}

export enum InyovaGrowAccountStatus {
  INITIAL = 'initial',
  OPENED = 'opened',
  FUNDED = 'funded',
  CLOSED = 'closed'
}

export interface Customer {
  accounts: CustomerAccountItem[];
  unified_accounts: CustomerUnifiedAccountItem[];
  app_location: 'ch' | 'de' | 'at';
  authentication_token?: string;
  created_at: string;
  email: string;
  first_name: string;
  has_crowd_investor_account: boolean;
  has_interest_account: boolean;
  id: number;
  interview_widget: boolean;
  inyova_id: number;
  inyova_pro: boolean;
  is_friend: boolean;
  is_test: boolean;
  language: string;
  last_name: string;
  lead_source: string;
  hide_personalisation: boolean;
  my_referral_customer?: string;
  otp_module: string;
  phone: string;
  provider: string;
  referral_code: string;
  saxo_bank_onboarding_done: boolean;
  saxo_data_renewal_expires_at: null | string;
  show_interest_account_banner: boolean;
  source_type_id: number;
  superhuman_visible: boolean;
  tag_manager: {
    first_time: boolean;
    origin: string;
  };
  test_version: string;
  total_current_amount: number;
  total_referrals: {
    is_friend?: boolean;
    referral_code?: string;
    free_feedays?: number;
    my_referral_customer?: string;
    referrallimit?: boolean;
  };
  track_id: string;
  uid: string;
  unverified_phone: string;
  verified_phone: string;
}
export interface CustomerUnifiedAccountItem {
  email?: string;
  id: string;
  isCrowdinvestor?: boolean;
  isGrowAccount?: boolean;
  kind: '3b' | '3a' | 'kid' | 'ci' | 'interest' | 'grow';
  owner_name: string;
  account_currency: string;
  step_status?: StepStatus | InyovaGrowAccountStatus;
}
export interface CustomerAccountItem {
  email?: string;
  id: string;
  isCrowdinvestor?: boolean;
  isGrowAccount?: boolean;
  kind: '3b' | '3a' | 'kid' | 'ci' | 'interest' | 'grow';
  owner_name: string;
  account_currency: string;
  step_status?: number;
}

export interface CustomerAccount {
  active: boolean;
  converted_after_deadline?: boolean;
  created_at: string;
  currency: string;
  current_amount: number;
  customer_journey: CustomerJourneyFlags;
  deposit_this_year: number;
  edit_mode: boolean;
  first_time_yellow: boolean;
  has_low_investment_balance: null | boolean;
  iban: string;
  id: string;
  initial_investment: string;
  inyova_fee: number;
  is_wisher: boolean;
  is_yova_yellow: boolean;
  kind: '3b' | '3a' | 'kid' | 'ci' | 'interest' | 'grow';
  liberty_account?: {
    qr_iban?: string;
    reference_number?: string;
  };
  liquidation_only: null | boolean;
  max_contribution_level?: 'large' | 'small' | 'transfer_only';
  minimum_investment: number;
  next_strategy_change_on: string;
  on_hold: boolean;
  onboarding_step: number;
  owner_name: string;
  product_type: string;
  ql_onboarding_step: number;
  risk_recheck_visible: boolean;
  savings_plan_social_proof: SavingsPlanSocialProof | null;
  step_status: number;
  strategy_edit_mode_disabled_at: string | null;
  strategy_edit_mode_enabled_at: string | null;
  tutorial_seen: boolean;
}

export interface SavingsPlanSocialProof {
  average: number;
  above_average: number;
  below_average: number;
  percentage: number;
}

export interface IbanOption {
  title: string;
  desc: string;
  type: 'account' | 'new';
  value?: string;
}

export interface IBANItem {
  id: string;
  type: string;
  attributes: {
    bank: string;
    iban: string;
  };
}

export interface CustomerJourneyFlags {
  account_opened: boolean;
  account_funded: boolean;
  id_verification_completed: boolean;
  customer_funded: boolean;
  compliance_done: boolean;
  account_data_filled: boolean;
  ql_flow_completed: boolean;
  risk_checked?: boolean;
}

export interface BankAccountDetail {
  bank: string;
  bic: string;
  address: string;
  city: string;
  country: string;
  zip: string;
  error?: string;
  errors?: string;
}

export interface ApiConfig {
  url: string | null;
  urlV3: string | null;
  accountID: string | null;
}

export class CustomError extends Error {
  status: number;
  error: { code: number };
  constructor(message: string, code: number) {
    super(message);
    this.status = code;
    this.error = { code };
  }
}

export enum PaymentOptions {
  CREDIT_CARD = 'creditCard',
  MOBILE_WALLETS = 'mobileWallets',
  PAYPAL = 'payPal',
  TWINT = 'twint',
  DIRECT_DEBIT = 'directDebit',
  BANK_TRANSFER = 'bankTransfer'
}
